jQuery(document).on('ready', function() {
	toggleMenu();
	pageChange();

	formHandler();

	jQuery('#form-error span, .lightbox-close').on('click', function(){
		removeFormAlert();
	});

	jQuery('input, textarea').on('click', function() {
		jQuery('#form-error').removeClass('active');
		jQuery(this).removeClass('invalid');
	});
});

function toggleMenu() {
	jQuery('#menu-icon, #menu header svg').on('click', function(){
		setTimeout(function() {
			jQuery('#menu-icon span').hide();
		}, 500);

		jQuery('#menu').toggleClass('active');
		jQuery('#bg-transitions, #pages').toggleClass('left');
		jQuery('#menu-icon').toggleClass('inactive');
	});
}

function pageChange() {
	jQuery('#menu li a').on('click', function(){
		var ID = jQuery(this).attr('data-id');
		var transitionContainer = jQuery('#bg-transitions');
		var pagesContainer = jQuery('#pages');

		jQuery('#menu').removeClass('active');
		transitionContainer.removeClass('left');
		pagesContainer.removeClass('left');
		jQuery('#menu-icon').removeClass('inactive');

		setTimeout(function() {
			jQuery('#blank-overlay').show(0).delay(1800).hide(0);
			transitionContainer.find('.bg-container').removeClass('active');
			transitionContainer.find('.bg-container[data-id=' + ID + ']').addClass('active');
			pagesContainer.find('.page').removeClass('active');
			pagesContainer.find('.page[data-id=' + ID + ']').addClass('active');
		}, 500);
	});
}

/**
 *	Function that handles the show of a lightbox
 */
function showLightbox(lightbox) {
	jQuery('.lightbox[data-name="' + lightbox + '"]').addClass('active');
	jQuery('body').addClass('overflow');

	bindLightboxClose();
}

/**
 *	Function that handles the hide of a lightbox
 */
function bindLightboxClose() {
	jQuery('.lightbox .lightbox-close').on('click', function(){
		jQuery(this).closest('.lightbox').removeClass('active');

		setTimeout(function() {
			jQuery('body').removeClass('overflow');
		}, 500);
	});
}

/**
 *	Check contact form
 */
function formHandler() {
	jQuery('#submit').on('click', function(event){
		event.preventDefault();
		removeFormAlert();

		var name = jQuery('#name').val();
		var email = jQuery('#email').val();
		var telephone = jQuery('#telephone').val();
		var message = jQuery('#message').val();

		var i = 0;

		if(name.match(/^[a-zA-Z-\s]{2,}/)) {
			i++;
		}
		else {
			jQuery('#name').addClass('invalid');
		}

		if(email.trim().match(/^[_\.0-9a-zA-Z-]+@([0-9a-zA-Z][0-9a-zA-Z-]+\.)+[a-zA-Z]{2,10}$/)) {
			i++;
		}
		else {
			jQuery('#email').addClass('invalid');
		}

		if(telephone.trim().match(/^0[1-9][0-9]{8}$/)) {
			i++;
		}
		else {
			jQuery('#telephone').addClass('invalid');
		}

		if(message.length >= 10) {
			i++;
		}
		else {
			jQuery('#message').addClass('invalid');
		}

		if(i == 4) {
			jQuery.post("/partials/formHandler.php", jQuery("#contactform").serialize(), function(response) {
				jQuery('.lightbox[data-name="contact"] .lightbox-close').trigger('click');

				setTimeout(function() {
					showLightbox('contact-success');
				}, 600);
			});
			return false;
		}
		else {
			jQuery('#form-error').addClass('active');
			return false;
		}
	});
}

function removeFormAlert() {  
	jQuery('#form-error').removeClass('active');
	jQuery('input, textarea').removeClass('invalid');
}